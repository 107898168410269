<template>
    <div class="create-edit-user">
        <Loader :loading="showLoader" />
        <v-toolbar class="custom-toolbar mb-4" flat dense>
            <v-btn icon @click="goBack">
                <v-icon size="40">mdi-arrow-left-bold-circle</v-icon>
            </v-btn>
            <v-toolbar-title class="custom-toolbar-title">
                {{ 'User Details' }}
            </v-toolbar-title>
            <v-btn class="pulse-animation mr-2" variant="tonal" @click="abortChanges" color="red">Abort</v-btn>
            <v-btn :disabled="!canSubmit" class="pulse-animation" variant="tonal"
                @click="isEdit ? editUser() : submitUser()" color="green">
                {{ isEdit ? 'Update' : 'Create' }}
            </v-btn>
        </v-toolbar>

        <!-- User Information Section -->
        <div class="user-section">
            <h2>User Information</h2>
            <v-row>
                <v-col cols="12" md="6">
                    <v-text-field label="First Name" v-model="user.firstName" variant="outlined" />
                </v-col>
                <v-col cols="12" md="6">
                    <v-text-field label="Last Name" v-model="user.lastName" variant="outlined" />
                </v-col>
                <v-col cols="12">
                    <v-text-field label="Email" v-model="user.email" variant="outlined" :error="emailExists"
                        :error-messages="emailExists ? 'Email already exists' : ''" />
                </v-col>
                <v-col cols="12">
                    <v-textarea label="Description" v-model="user.description" variant="outlined" />
                </v-col>
            </v-row>
        </div>

        <!-- Location Section -->
        <div class="location-section">
            <h2>Campus</h2>
            <v-card-text class="tile-description">Assign the user the campus where their primary workstation is
                located.</v-card-text>
            <v-select :items="locations" label="Campus" v-model="user.campusName" variant="outlined" />
        </div>

        <!-- Activity Section -->
        <div class="activity-section">
            <h2>Activity</h2>
            <v-card-text class="tile-description">
                A user's activity can be limited to a certain period of time. This affects whether the user can log into
                the system.
            </v-card-text>
            <v-switch label="Active" v-model="user.enabled" inset color="success"
                :class="user.enabled ? 'active-switch' : ''"></v-switch>
            <v-row>
                <v-col cols="12" md="5">
                    <v-text-field label="Active From" v-model="user.activeFrom" type="date" variant="outlined"
                        :disabled="!user.enabled" prepend-icon="mdi-calendar" />
                </v-col>
                <v-col cols="12" md="5">
                    <v-text-field label="Active Until" v-model="user.activeUntil" type="date" variant="outlined"
                        :disabled="!user.enabled" prepend-icon="mdi-calendar" />
                </v-col>
                <v-col cols="12" md="2">
                    <v-btn color="white" class="button" @click="resetActiveUntil">Reset</v-btn>
                </v-col>
            </v-row>
        </div>

        <!-- Roles Section -->
        <div class="roles-groups-section">
            <h2>Roles</h2>
            <v-card-text class="tile-description">Use roles to manage a user's software permissions.</v-card-text>
            <v-autocomplete clearable chips label="Select Roles" v-model="user.roles" :items="roles" multiple
                variant="outlined"></v-autocomplete>
        </div>
    </div>
</template>

<script>
import { ref, defineComponent, onMounted, reactive, computed, watch } from 'vue'
import { debounce } from 'lodash'
import moment from 'moment'
import Loader from '@/components/general/Loader.vue'
import { useRoute } from 'vue-router'
import { getRoles, getUsers, createUser, linkRoleToUser, deleteUser, unlinkRolesFromUser, updateUser } from '@/controllers/BackboneAPI'
import { getStoredData, getCampuses, isStaingOrQa, createUserProfile, updateUserProfile } from '@/controllers/BaseController'
import Swal from 'sweetalert2'
import { useRouter } from 'vue-router'
import { showToast } from '@/services/utils'
import { dbPromise } from '@/services/utils'
import { removeStoredItem } from '@/services/utils'
import lodash from 'lodash'

export default defineComponent({
    name: 'CreateEditUser',
    components: {
        Loader,
    },
    setup() {
        const showLoader = ref(true)
        const allUsers = ref([])
        const allRoles = ref([])
        const router = useRouter()

        const emailExists = ref(false)

        // Reactive user data
        const user = reactive({
            firstName: '',
            lastName: '',
            email: '',
            description: '',
            campusName: '',
            enabled: true,
            activeFrom: moment().format('YYYY-MM-DD'),
            activeUntil: null,
            roles: [],
            groups: [],
        })
        const originalUser = ref([])

        // Data Lists
        const locations = ref([])
        const roles = ref([])
        const campuses = ref([])
        const route = useRoute()
        const isEdit = computed(() => !!route.params.id)

        const goBack = () => {
            window.history.back()
        }

        // Check if email exists in the list
        const checkEmailExists = debounce(() => {
            if (isEdit.value && user.email === originalUser.value?.email) {
                // ✅ Do not show warning if the email is unchanged
                emailExists.value = false
                return
            }
            // ✅ Check if email exists among all users
            emailExists.value = allUsers.value.some(userItem => userItem.email === user.email)
        }, 500)

        watch(() => user.email, checkEmailExists)

        // Computed: Validate form submission
        const canSubmit = computed(() => {
            return (
                user.firstName.trim() !== '' && user.lastName.trim() !== '' && user.email.trim() !== '' && user.campusName !== '' && user.roles.length > 0
            )
        })

        // Reset active until date
        const resetActiveUntil = () => {
            user.activeUntil = null
        }

        const editUser = async () => {
            const noChanges = lodash.isEqual(lodash.cloneDeep(user), lodash.cloneDeep(originalUser.value))

            if (noChanges) {
                // **Show Swal warning if nothing changed**
                await Swal.fire({
                    title: 'No Changes Detected',
                    text: 'No need to update. Nothing has changed.',
                    icon: 'warning',
                    confirmButtonText: 'OK',
                })
                return
            }

            try {
                // **Show Swal loader**
                Swal.fire({
                    title: 'Updating...',
                    text: 'Please wait while the user details are being updated.',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    didOpen: () => {
                        Swal.showLoading()
                    },
                })

                // **Step 1: Update User Details**
                const updatedUser = { ...originalUser.value }
                updatedUser.firstName = user.firstName
                updatedUser.lastName = user.lastName
                updatedUser.email = user.email
                updatedUser.description = user.description
                updatedUser.location = user.location
                updatedUser.activeFrom = user.activeFrom
                updatedUser.activeUntil = user.activeUntil

                await updateUser(user.id, updatedUser)
                showToast({ text: 'User details updated successfully!', type: 'success' })

                // **Step 2: Update Roles if Changed**
                const oldRoles = allRoles.value.filter(role => (originalUser.value.roles || []).includes(role.name)).map(role => role.id)
                const newRoles = allRoles.value.filter(role => (user.roles || []).includes(role.name)).map(role => role.id)

                if (JSON.stringify([...oldRoles].sort()) !== JSON.stringify([...newRoles].sort())) {
                    showToast({ text: 'Updating roles...', type: 'default' })

                    // **Unlink Old Roles First**
                    await unlinkRolesFromUser(user.id, oldRoles)
                    showToast({ text: 'Old roles unlinked.', type: 'success' })

                    // **Link New Roles**
                    await linkRoleToUser(user.id, newRoles)
                    showToast({ text: 'New roles assigned successfully!', type: 'success' })
                }

                // **Step 3: Update User Profile if Exists**
                if (originalUser.value.profile && originalUser.value.profile.id) {
                    showToast({ text: 'Updating user profile...', type: 'default' })

                    // **Get New Campus ID**
                    const campus = campuses.value.find(campus => campus.name === user.campusName)
                    if (!campus) throw new Error('Selected campus not found.')
                    let campusId = campus.id

                    // **Prepare Profile Payload**
                    const updatedProfile = { ...originalUser.value.profile }
                    updatedProfile.name = `${user.firstName} ${user.lastName}`
                    updatedProfile.displayName = `${user.firstName} ${user.lastName}`
                    updatedProfile.displayEmail = user.email
                    updatedProfile.email = user.email
                    updatedProfile.campus.id = campusId

                    await updateUserProfile(updatedProfile.id, updatedProfile)
                    showToast({ text: 'User profile updated successfully!', type: 'success' })
                }

                // **Step 4: Clear Cache and Redirect**
                await deleteUsersFromCache()

                Swal.fire({
                    title: 'Success!',
                    text: 'User updated successfully.',
                    icon: 'success',
                    timer: 3000,
                    showConfirmButton: false,
                }).then(() => {
                    router.replace({
                        path: '/menu/users-management/users',
                        name: 'UsersList',
                        meta: { requiresAuth: true },
                    })
                })
            } catch (error) {
                console.error('Error updating user:', error)
                Swal.fire({
                    title: 'Error',
                    text: 'Failed to update user. Please try again later.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                })
            }
        }

        const deleteUsersFromCache = async () => {
            const db = await dbPromise
            const tx = db.transaction('users', 'readwrite')
            const store = tx.store

            // Delete all keys from IndexedDB 'users' store
            const keys = await store.getAllKeys()
            for (const key of keys) {
                await store.delete(key)
                console.log(`Deleted cache entry for key: ${key}`)
            }
            await tx.done
            console.log('All user-related data deleted from the users store.')

            // Clear additional stored data for 'userProfiles' and 'users'
            await removeStoredItem('userProfiles')
            await removeStoredItem('users')
            console.log('userProfiles and users data cleared from storage.')
        }

        const submitUser = async () => {
            if (!canSubmit.value) {
                showToast({ text: 'Please fill out all required fields.', type: 'error' })
                return
            }

            let userId = null // Store user ID for rollback if needed

            try {
                // Show Swal loader
                Swal.fire({
                    title: 'Processing...',
                    text: 'Please wait while the user is being created.',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    didOpen: () => {
                        Swal.showLoading()
                    },
                })

                // ** Get Campus ID **
                const campus = campuses.value.find(campus => campus.name === user.campusName)
                if (!campus) throw new Error('Selected campus not found.')
                let campusId = campus.id

                // ** Prepare User Payload **
                let userPayload = {
                    activeFrom: user.enabled ? moment(user.activeFrom).format('YYYY-MM-DDTHH:mm:ssZ') : null,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    email: user.email,
                    enabled: user.enabled,
                    username: `${user.firstName}${user.lastName}`,
                    description: user.description,
                }

                if (user.enabled) {
                    userPayload.active = true
                }

                if (user.enabled && user.activeUntil) {
                    userPayload.activeUntil = moment(user.activeUntil).format('YYYY-MM-DDTHH:mm:ssZ')
                }

                // ** Create User **
                let userResponse = await createUser(userPayload)
                userId = userResponse.id
                showToast({ text: 'User Created Successfully.', type: 'success' })

                // ** Prepare Role Payload (Simple Array) **
                let rolePayload = user.roles.map(role => {
                    let foundRole = allRoles.value.find(roleItem => roleItem.name === role)
                    if (!foundRole) throw new Error(`Role '${role}' not found.`)
                    return foundRole.id
                })

                // ** Link Roles to User **
                showToast({ text: 'Linking Roles...', type: 'default' })
                try {
                    await linkRoleToUser(userId, rolePayload)
                    showToast({ text: 'Roles Linked Successfully.', type: 'success' })
                } catch (roleError) {
                    await deleteUser(userId)
                    throw new Error('Failed to assign roles. User creation has been rolled back.')
                }

                // ** Prepare Profile Payload **
                let profilePayload = {
                    name: `${user.firstName} ${user.lastName}`,
                    displayName: `${user.firstName} ${user.lastName}`,
                    email: user.email,
                    displayEmail: user.email,
                    enableAnalytics: false,
                    enableLocationPublishing: true,
                    publishEmail: true,
                    active: user.enabled,
                    campusId: campusId,
                }

                // ** Create User Profile **
                showToast({ text: 'Creating User Profile...', type: 'default' })
                try {
                    await createUserProfile(profilePayload)
                    showToast({ text: 'User Profile Created Successfully.', type: 'success' })
                } catch (profileError) {
                    await deleteUser(userId)
                    throw new Error('Failed to create user profile. User creation has been rolled back.')
                }

                // ** Success: Show Swal & Redirect **
                await deleteUsersFromCache()
                Swal.fire({
                    title: 'Success!',
                    text: 'User created successfully.',
                    icon: 'success',
                    timer: 3000,
                    showConfirmButton: false,
                }).then(() => {
                    router.replace({
                        path: '/menu/users-management/users',
                        name: 'UsersList',
                        meta: { requiresAuth: true },
                    })
                })
            } catch (error) {
                console.error('Failed to create user:', error)

                // ** Error Handling: Show Message **
                Swal.fire({
                    title: 'Error',
                    text: error.message || 'An error occurred while creating the user.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                })
            } finally {
                showLoader.value = false
            }
        }

        // Abort changes
        const abortChanges = () => {
            console.log('Aborting changes...')
        }

        // Fetch data on component mount
        onMounted(async () => {
            try {
                showLoader.value = true
                allUsers.value = await getUsers()

                let CAMPUSES = await getCampuses()
                CAMPUSES = CAMPUSES.filter(campus => campus.active)
                if (await isStaingOrQa()) {
                    CAMPUSES = CAMPUSES.filter(campus => campus.name?.toLowerCase() !== 'hamburg')
                }
                campuses.value = CAMPUSES
                locations.value = CAMPUSES.map(campus => campus.name)

                const fetchedRoles = await getRoles()
                allRoles.value = fetchedRoles
                roles.value = fetchedRoles.map(role => role.name)

                if (isEdit.value) {
                    const storedUser = await getStoredData(route.params.id)
                    Object.assign(user, storedUser)
                    // user.location = storedUser.campusName;
                    originalUser.value = { ...storedUser }
                    console.log(JSON.parse(JSON.stringify(originalUser.value)))
                    console.log(JSON.parse(JSON.stringify(user)))
                }
            } catch (error) {
                console.error('Failed to load data:', error)
            } finally {
                showLoader.value = false
            }
        })

        return {
            showLoader,
            user,
            locations,
            roles,
            campuses,
            allUsers,
            emailExists,
            checkEmailExists,
            goBack,
            submitUser,
            abortChanges,
            originalUser,
            resetActiveUntil,
            canSubmit,
            isEdit,
            allRoles,
            router,
            deleteUsersFromCache,
            editUser,
        }
    },
})
</script>

<style scoped>
.create-edit-user {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.custom-toolbar {
    background-color: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(10px);
    color: #333;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 8px 16px;
}
</style>

<style scoped>
::v-deep(input[type='date']::-webkit-calendar-picker-indicator) {
    display: none;
    -webkit-appearance: none;
}

/* Optional: Adjust padding for better alignment after hiding the native icon */
::v-deep(input[type='date']) {
    padding-right: 10px;
}

.create-edit-user {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.custom-toolbar {
    background-color: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(10px);
    color: #333;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 8px 16px;
    display: flex;
    align-items: center;
}

@supports (-webkit-touch-callout: none) {
    .custom-toolbar {
        position: sticky;
        top: 0;
        z-index: 1000;
    }
}

.custom-toolbar-title {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 1.5rem;
    color: #333;
    margin-left: 10px;
}

.toolbar-icon {
    height: 30px;
}

.user-section,
.location-section,
.activity-section,
.roles-groups-section {
    padding: 1rem;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    background-color: #fff;
}

.actions {
    margin-top: 2rem;
}

h2 {
    margin-bottom: 1rem;
    font-size: 1.25rem;
    color: #333;
}

.clickable-text-field {
    cursor: pointer;
}

.tile-description {
    margin-bottom: 1.5rem;
    font-size: 0.9rem;
    color: #666;
}

.button {
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
}

.button.white {
    background-color: #fff;
    color: #000;
    border: 1px solid #ddd;
}

.button.white:hover {
    background-color: #f0f0f0;
}

@media screen and (max-width: 768px) {
    h2 {
        font-size: 1.15rem;
    }

    .create-edit-user {
        padding: 0.5rem;
    }
}
</style>
